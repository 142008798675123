html, body {
  height: 99%;
  width: 99%;
}

div.app {
  height: 100%;
  width: 100%;
}
div#root{
  height: 100%;
  width: 100%;
}

.app .contents {
  width: 100%;
  height: 100%;
}

.app .contents .mode {
  padding: 10px 0;
}

.app .contents .code {
  background: #f5f5f5;
  overflow: auto;
}
